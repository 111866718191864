.scroll-button {
  position: fixed;
  left: 30px;
  bottom: 30px;
  cursor: pointer;
}

.scroll-button-img {
  width: 55px;
  height: 55px;
}